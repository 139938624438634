import React from 'react'

import { MainContent } from '@presentation/containers'
import { FAQ } from '@presentation/pages/beneficiaries/components/FAQ'

const BeneficiariesLayout: React.FCC = ({ children }) => (
  <MainContent aside={<FAQ />}>{children}</MainContent>
)

export default BeneficiariesLayout
