import React from 'react'

import { Section } from '@presentation/components'
import { MainContent } from '@presentation/containers'
import { useSimulation } from '@presentation/providers/SimulationProvider'

type SimulationLayoutProps = {}

const SimulationLayout: React.FCC<SimulationLayoutProps> = ({ children }) => {
  const { section } = useSimulation()

  return (
    <MainContent aside={<Section section={section} />}>{children}</MainContent>
  )
}

export default SimulationLayout
