import React from 'react'

import { Button, Text } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import Router from 'next/router'

import { ErrorProps } from './Error.props'
import { Root } from './Error.styles'

const ACTIONS = {
  BUTTON_UNEXPECTED_ERROR: B2CUtils.actions.general.BUTTON_UNEXPECTED_ERROR,
}

const Error = ({
  title = 'Opss!',
  description = 'Houve um problema, por favor tente novamente.',
  buttonText = 'Ir para página inicial',
  onClick = () => Router.push('/'),
}: ErrorProps) => {
  return (
    <Root>
      <div>
        <Text variant="h3" component="h2">
          {title}
        </Text>
        <Text variant="body2" className="description">
          {description}
        </Text>
        <Button
          color="primary"
          type="submit"
          variant="outlined"
          title={buttonText}
          onClick={onClick}
          fullWidth
          parentName={ACTIONS.BUTTON_UNEXPECTED_ERROR}
        >
          {buttonText}
        </Button>
      </div>
    </Root>
  )
}

export default Error
