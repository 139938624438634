import { makeProposalConsumer, makeStorageProvider } from '@azos/core'
import { NotifyAbandonedCartService } from '@data/services'

import { makeNotificationsRepository } from '../repositories'

export const makeNotifyAbandonedCartService = () =>
  new NotifyAbandonedCartService(
    makeNotificationsRepository(),
    makeProposalConsumer(),
    makeStorageProvider(),
  )
