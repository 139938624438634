import React from 'react'

type SimulationContextData = {
  section: number
  setSection: (value: number) => void
}

type SimulationProviderProps = {}

const SimulationContext = React.createContext({} as SimulationContextData)

export const SimulationProvider: React.FCC<SimulationProviderProps> = ({
  children,
}) => {
  const [section, setSection] = React.useState<number>(1)

  const value = {
    section,
    setSection,
  }

  return (
    <SimulationContext.Provider value={value}>
      {children}
    </SimulationContext.Provider>
  )
}

export const useSimulation = () => {
  const context = React.useContext(SimulationContext)

  if (!context) {
    throw new Error('The useSimulation should within SimulationProvider')
  }

  return context
}
