import React from 'react'

import { Button, Input, Select, Text, InputCPF, InputPhone } from '@azos/shared'
import { Formik } from 'formik'

import { initialValues, pixTypeOptions } from './CashbackForm.data'
import { CashbackFormProps } from './CashbackForm.props'
import { Form } from './CashbackForm.styles'
import { validationSchema } from './CashbackForm.validation'

const CashbackForm: React.FCC<CashbackFormProps> = ({ onSubmit }) => {
  const PixInput: React.VFC<any> = ({ inputType, ...props }) => {
    const inputTypes: { [key: string]: JSX.Element } = {
      cpf: <InputCPF {...props} />,
      phone: <InputPhone {...props} />,
      default: <Input {...props} />,
    }

    return inputTypes[inputType] ?? inputTypes.default
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        isValid,
        isSubmitting,
      }) => {
        const isDisabled = isValid && isSubmitting
        return (
          <Form>
            <div className="cashback-form__content">
              <Text variant="h4">Preencha para garantir o benefício.</Text>

              <Text variant="body1" className="cashback-form__subtitle">
                O cashback de 20% sobre a primeira fatura é exclusivo para
                pagamento com cartão. O valor será pago em até 8 dias na sua
                conta, através do Pix do segurado ou segurada.
              </Text>

              <Select
                id="keyType"
                name="keyType"
                label="Qual o tipo de chave PIX?"
                autoFocus
                value={values.keyType}
                options={pixTypeOptions}
                onChange={type => {
                  setFieldValue('keyType', type, true)
                  setFieldValue('keyPix', '', false)
                }}
                error={touched.keyType && !!errors.keyType}
                helperText={touched.keyType && errors.keyType}
                fullWidth
              />

              <PixInput
                fullWidth
                id="keyPix"
                name="keyPix"
                label="Qual seu PIX?"
                error={touched.keyPix && !!errors.keyPix}
                helperText={touched.keyPix && errors.keyPix}
                value={values.keyPix}
                onChange={handleChange}
                inputType={values.keyType}
              />
            </div>

            <Button
              type="submit"
              title="Informar Pix"
              disabled={isDisabled}
              fullWidth
            >
              Informar Pix
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

export default CashbackForm
