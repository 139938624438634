import { SWRServices } from '@azos/core'
import { LoadProfessionsUseCase } from 'domain/usecases'

type Response = LoadProfessionsUseCase.Response

export type ILoadProfessionsService = SWRServices<Response>

export class LoadProfessionsService implements ILoadProfessionsService {
  constructor(private readonly professionsRepository: LoadProfessionsUseCase) {}

  getUrl(): string {
    return `/profession`
  }

  async execute(): Promise<Response> {
    const professions = await this.professionsRepository.load()

    return professions
  }
}
