import React from 'react'

import { Utils as B2CUtils } from '@data/utils'
import FavoriteIcon from '@mui/icons-material/FavoriteBorder'
import { StepperItemProps } from '@presentation/components/Stepper'

const ACTIONS = {
  VIEWER_COVERAGE_SELECT: B2CUtils.actions.general.VIEWER_COVERAGE_SELECT,
  VIEWER_HEALTH_CHECK: B2CUtils.actions.general.VIEWER_HEALTH_CHECK,
  VIEWER_PERSONAL_DATA: B2CUtils.actions.general.VIEWER_PERSONAL_DATA,
  VIEWER_SIMULATION_RESUME: B2CUtils.actions.general.VIEWER_SIMULATION_RESUME,
}

export const steps: StepperItemProps[] = [
  {
    label: 'Dados pessoais',
    action: ACTIONS.VIEWER_PERSONAL_DATA,
  },
  {
    label: 'Escolher cobertura',
    action: ACTIONS.VIEWER_COVERAGE_SELECT,
  },
  {
    label: 'Resumo da simulação',
    action: ACTIONS.VIEWER_SIMULATION_RESUME,
  },
  {
    content: <FavoriteIcon />,
    label: 'Avaliação de saúde',
    action: ACTIONS.VIEWER_HEALTH_CHECK,
  },
]
