import { Storage, SyncServices } from '@azos/core'

export type UTMData = {
  [key: string]: string | string[]
}

type Request = void
type Response = UTMData | undefined

export type IUTMConsumer = SyncServices<Request, Response>

export class UTMConsumer implements IUTMConsumer {
  constructor(private readonly storage: Storage) {}

  execute(): Response {
    const data = this.storage.getJSON<UTMData>('utm')

    if (!!data && !!data.utm_source) {
      return data
    }

    return undefined
  }
}
