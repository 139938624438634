export enum CheckoutPixTypes {
  phone = 'phone',
  cpf = 'cpf',
  email = 'email',
  others = 'outros',
}

export type Contract = {
  contractId: string
  isPaid: boolean
}
