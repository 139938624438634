import React from 'react'

import { useAuth } from '@azos/account'
import { UserMenu } from '@azos/shared'
import { env } from '@main/config/env'
import Router, { useRouter } from 'next/router'

import { Root } from './PrivateContainer.styles'

type ContentControlProps = {
  control?: React.ReactNode
}

const Container: React.FCC<ContentControlProps> = ({ children }) => {
  const { user, logout, isAllow } = useAuth()
  const { pathname } = useRouter()

  const handleLogout = React.useCallback(async () => {
    await logout()
    await Router.push(`/login?origin=${pathname}`)
  }, [logout, pathname])

  const handleRedirect = React.useCallback(
    async () => (window.location.href = `${env.DASHBOARD_URL}`),
    [],
  )

  return (
    <Root>
      <UserMenu
        className="content--user-menu"
        user={user}
        onSignOut={handleLogout}
        onDashboard={handleRedirect}
      />
      {isAllow && <>{children}</>}
    </Root>
  )
}

export default Container
