import React from 'react'

import { useAuth } from '@azos/account'
import { Button, Icon, Text, Utils } from '@azos/shared'

import { CashbackSuccessProps } from './CashbackSuccess.props'
import { IconContainer, Root } from './CashbackSuccess.styles'

const CashbackSuccess: React.FCC<CashbackSuccessProps> = ({
  pixValue,
  onDone,
}) => {
  const { user } = useAuth()

  return (
    <Root>
      <div className="cashback-success__content">
        <IconContainer className="payment_status__icon">
          <Icon.Checkout.PixPaid />
        </IconContainer>

        <Text variant="h3" className="cashback-success__title">
          Recebemos suas informações
        </Text>

        <Text variant="body1" className="cashback-success__subtitle">
          Agora é com a gente! Em até 8 dias você vai receber o valor do
          benefício na conta abaixo:
        </Text>

        <div className="cashback-success__data-content">
          <div className="cashback-success__data-content-wrapper">
            <span>CPF do Títular:</span>
            <strong>
              {Utils.formatter.formatCPF(user?.insuredUser?.cpf ?? '')}
            </strong>
          </div>

          <div className="cashback-success__data-content-wrapper">
            <span>Chave Pix:</span>
            <strong>{pixValue}</strong>
          </div>
        </div>
      </div>

      <Button onClick={onDone}>Acompanhe o status do seu seguro</Button>
    </Root>
  )
}

export default CashbackSuccess
