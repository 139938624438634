import React from 'react'
import { animated, Transition } from 'react-spring'

import { useWizard } from '../../providers'

type WizardProps = {
  children: React.ReactNode[]
}

const WizardSwipper: React.FCC<WizardProps> = ({ children }) => {
  const { step: currentStep, transition, setTransition } = useWizard()

  const elements = React.useMemo(() => {
    const childrens = Array.isArray(children) ? children : [children]
    return childrens[currentStep] || null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep])

  const elementRender = React.useMemo(() => elements, [elements])

  React.useEffect(() => {
    setTransition(null)
  }, [setTransition])

  if (currentStep === 0 && transition !== 'backward') {
    return <div>{elementRender}</div>
  }

  return (
    <Transition
      items={[elementRender]}
      delay={250}
      from={{
        opacity: 0,
        transform:
          transition === 'forward' ? 'translateY(50%)' : 'translateY(-50%)',
      }}
      enter={{
        opacity: 1,
        transform: 'translateY(0%)',
        onResolve: () => setTransition(null),
      }}
    >
      {(interpolate, item) => (
        <animated.div style={interpolate}>{item}</animated.div>
      )}
    </Transition>
  )
}

export default WizardSwipper
