import React from 'react'

import analytics from '@azos/analytics'
import { CallPage } from '@presentation/components'

const CallpageContainer: React.VFC = () => {
  const handleClick = React.useCallback(() => {
    analytics.callpage.activate.execute()
  }, [])

  return <CallPage onCallPageClick={handleClick} />
}

export default CallpageContainer
