import React from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useWizard } from '@presentation/components'
import { Formik, FormikHelpers } from 'formik'

import { Control } from './components'
import { FormProps as MyFormProps } from './Form.props'
import { Form } from './Form.styles'

type FormValues = any
type Props = MyFormProps<FormValues>

const MyForm: React.FCC<Props> = ({
  children,
  initialValues,
  onSubmit: finishSubmit,
}) => {
  const {
    prevStep,
    nextStep,
    isFirstStep,
    isLastStep,
    stepConfig,
    isTransition,
  } = useWizard()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isScrollable = React.useMemo<boolean>(
    () => stepConfig.scrollable && isMobile,
    [isMobile, stepConfig.scrollable],
  )
  const disableControls = React.useMemo<boolean>(
    () => stepConfig.disableControls || isScrollable,
    [isScrollable, stepConfig.disableControls],
  )
  const disabledPrevButton = React.useMemo<boolean>(
    () => isFirstStep || isTransition,
    [isFirstStep, isTransition],
  )
  const disabledNextButton = React.useMemo<boolean>(
    () => stepConfig.disabledNextButton || isTransition,
    [isTransition, stepConfig.disabledNextButton],
  )
  const requiredValid = React.useMemo<boolean>(
    () => stepConfig.requiredValid || false,
    [stepConfig.requiredValid],
  )
  const hideNextButton = React.useMemo<boolean>(
    () => stepConfig.hideNextButton || false,
    [stepConfig.hideNextButton],
  )

  const validationSchema = React.useMemo(
    () => stepConfig.schema || null,
    [stepConfig.schema],
  )

  const handleSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<any>,
  ) => {
    await stepConfig.onSubmit?.(values)

    if (isLastStep) {
      await finishSubmit?.(values)
      return
    }

    helpers.setTouched({})
    nextStep()
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount={false}
      validateOnBlur={false}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form $onTransition={isTransition}>
        {children}
        {!disableControls && (
          <div className="control-container">
            <Control
              onPrev={prevStep}
              disabled={isTransition}
              disabledPrevButton={disabledPrevButton}
              disabledNextButton={disabledNextButton}
              hideNextButton={!isLastStep && hideNextButton}
              requiredValid={requiredValid}
            />
          </div>
        )}
      </Form>
    </Formik>
  )
}

export default MyForm
