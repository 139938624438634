import React from 'react'

type Callback = () => void

export const useTimeout = (callback: Callback, delay: number): void => {
  const savedCallback = React.useRef<Callback>(() => ({}))

  React.useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  React.useEffect(() => {
    const id = setTimeout(() => savedCallback.current(), delay)
    return () => {
      clearTimeout(id)
    }
  }, [delay])
}
