import { Storage, SyncServices } from '@azos/core'

type Response =
  | {
      [key: string]: string
    }
  | undefined

export type ISessionConsumer = SyncServices<void, Response>

export class SessionConsumer implements ISessionConsumer {
  constructor(private readonly storage: Storage) {}

  execute(): Response {
    const ga_id = this.storage.get<string>('_ga')
    const gaexp = this.storage.get<string>('_gaexp')
    const fbp = this.storage.get<string>('_fbp')
    const fbc = this.storage.get<string>('_fbc')
    const first_session = this.storage.get<string>('FirstSession')
    const returning_session = this.storage.get<string>('ReturningSession')

    const data = {
      ga_id,
      gaexp,
      fbp,
      fbc,
      first_session,
      returning_session,
    }

    const hasData = Object.values(data)
      .map(item => !!item)
      .includes(true)

    return hasData ? data : undefined
  }
}
