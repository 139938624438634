import React from 'react'

import analytics, { setupAnalytics } from '@azos/analytics'
import { css } from '@azos/shared'
import { CacheProvider } from '@emotion/react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { env } from '@main/config/env'
import googleTagManager from '@main/config/googleTagManager'
import growthbookConfig from '@main/config/growthbook'
import { CssBaseline, GlobalStyles } from '@mui/material'
import { MainContainer } from '@presentation/containers'
import { ClearsaleProvider } from '@presentation/providers/ClearSaleProvider'
import MainProvider from '@presentation/providers/MainProvider'
import { AppPropsWithLayout } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import smoothscroll from 'smoothscroll-polyfill'
import clearSale from 'static/clearsaleSDK'
import { v4 as uuid } from 'uuid'

import createEmotionCache from '../utility/createEmotionCache'

const clientSideEmotionCache = createEmotionCache()

const globalStyle = css`
  html,
  body,
  #__next {
    height: 100%;
  }

  body {
    background: #f3f4f6;
  }
`

const growthbookConfigByEnv = {
  ...growthbookConfig,
  clientKey: growthbookConfig.clientKey[env.environment],
}
const growthbook = new GrowthBook({
  ...growthbookConfigByEnv,
  trackingCallback: (experiment, result) => {
    analytics.simulation.viewExperiment.execute({
      experimentId: experiment.key,
      variationId: result.key,
    })
  },
})

function MyApp({
  emotionCache = clientSideEmotionCache,
  Component,
  pageProps,
}: AppPropsWithLayout) {
  const router = useRouter()
  const getLayout = Component?.getLayout || (page => page)
  const clearSaleSessionId = React.useMemo(() => uuid(), [])

  React.useEffect(() => {
    setupAnalytics(googleTagManager, growthbook)
    smoothscroll.polyfill()
  }, [])

  return (
    <>
      <Head>
        <title>Azos | Um seguro para sua vida</title>
        <link rel="manifest" href="/manifest.json" />
        <link rel="apple-touch-icon" href="/assets/png/96x96.png" />
        <link
          rel="canonical"
          href={`https://app.azos.com.br${router.pathname}`}
        />
        <meta name="theme-color" content="#00D563" />
        <meta name="apple-mobile-web-app-status-bar" content="#F3F4F6" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <ClearsaleProvider clearsaleSessionId={clearSaleSessionId}>
        <CacheProvider value={emotionCache}>
          <GrowthBookProvider growthbook={growthbook}>
            <MainProvider>
              <MainContainer>
                {getLayout(<Component {...pageProps} />)}
              </MainContainer>
            </MainProvider>
          </GrowthBookProvider>
          <CssBaseline />
          <GlobalStyles styles={globalStyle} />
        </CacheProvider>
      </ClearsaleProvider>

      <Script
        id="clearSale"
        dangerouslySetInnerHTML={{
          __html: clearSale(clearSaleSessionId),
        }}
      />
      <Script src="/google-optimize" strategy="beforeInteractive" />
    </>
  )
}

export default MyApp
