import React from 'react'

import { StepIconProps as BaseStepIconProps } from '@mui/material'
import { styled } from '@mui/material/styles'

import { StepperText } from './StepperText'

type StepIconRootProps = {
  ownerState: {
    completed?: boolean
    active?: boolean
  }
}

type StepIconProps = BaseStepIconProps & {
  content?: React.ReactNode
}

const StepIconRoot = styled('div')<StepIconRootProps>(
  ({ theme, ownerState }) => ({
    backgroundColor: 'transparent',
    borderColor: '#333',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    color: '#333',

    height: 50,
    width: 50,
    zIndex: 1,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ...(ownerState.active && {
      color: '#FFF',
    }),

    ...(ownerState.completed && {
      backgroundColor: 'white',
      color: 'black',
    }),
  }),
)

export const StepIcon: React.FCC<StepIconProps> = ({
  children,
  icon,
  active,
  completed,
}) => {
  return (
    <StepIconRoot ownerState={{ completed, active }}>
      {children || <StepperText label={icon} />}
    </StepIconRoot>
  )
}
