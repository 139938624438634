import React from 'react'

import NextLink from 'next/link'

type ALinkProps = {
  href: string
  title?: string
}

const Link: React.FCC<ALinkProps> = ({ children, href, title }) => {
  return (
    <NextLink href={href}>
      <a title={title}>{children}</a>
    </NextLink>
  )
}

export default Link
