import { makeProposalConsumer, makeProposalProducer } from '@azos/core'
import {
  CreateQuotationService,
  LoadQuotationByIdService,
  UpdateQuotationService,
} from '@data/services'

import { makeSessionConsumer, makeUTMConsumer } from '../adapters'
import { makeQuotationsRepository } from '../repositories'

export const makeCreateQuotationService = () =>
  new CreateQuotationService(
    makeQuotationsRepository(),
    makeUTMConsumer(),
    makeSessionConsumer(),
    makeProposalProducer(),
  )

export const makeLoadQuotationByIdService = () =>
  new LoadQuotationByIdService(makeQuotationsRepository())

export const makeUpdateQuotationService = () =>
  new UpdateQuotationService(makeQuotationsRepository(), makeProposalConsumer())
