import { css } from '@azos/shared'
import ButtonBase from '@mui/material/ButtonBase'
import { styled } from '@mui/material/styles'

const SHRINK_DELAY = 3

export const ModalContainer = styled('div')(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)};

    & > .modal-content-button {
      width: 100%;
      background: ${theme.palette.background.default};

      i,
      svg {
        font-size: 1.5rem;
      }
    }
  `,
)

export const Root = styled(ButtonBase)(
  ({ theme }) => css`
    @keyframes fadeOut {
      0% {
        opacity: 1;
        pointer-events: none;
      }

      100% {
        opacity: 0;
        margin: 0;
        height: 0;
        width: 0;
      }
    }

    @keyframes fadeIn {
      0% {
        transform: translateY(-10%);
      }

      100% {
        transform: translateY(0%);
      }
    }

    @keyframes shrink {
      0% {
        width: calc(100% - 56px);
        max-width: 600px;
      }

      100% {
        width: 56px;
      }
    }

    position: absolute;
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};

    ${theme.breakpoints.up('md')} {
      position: fixed;
    }

    overflow: hidden;
    width: calc(100% - 56px);
    max-width: 600px;
    min-height: 56px;
    z-index: ${theme.zIndex.drawer};
    display: none;
    align-items: center;

    background: ${theme.palette.common.black};
    border: none;
    border-radius: ${theme.spacing(2)};
    color: ${theme.palette.common.white};
    padding: ${theme.spacing('12px', 3)};

    animation-name: fadeIn, shrink;
    animation-delay: 0.1s, ${SHRINK_DELAY}s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-duration: 0.75s;

    cursor: pointer;

    .content {
      &-icon {
        font-size: 1.5rem;
      }

      &-text {
        margin-left: ${theme.spacing(2)};
        animation-name: fadeOut;
        animation-delay: ${SHRINK_DELAY}s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-duration: 0.3s;
      }
    }
  `,
)
