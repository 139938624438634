import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    text-align: center;

    .cashback-notify-content {
      &__subtitle {
        margin: ${theme.spacing(4, 0, 6)};
        color: ${theme.palette.grey[400]};
      }

      &__button-group {
        margin-top: ${theme.spacing(6)};
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          height: 48px;
        }

        & > button + button {
          margin-top: ${theme.spacing(3)};
        }
      }
    }
  `,
)
