import React from 'react'

import { CheckoutPaymentFrequency } from '@azos/core'
import { Button, Icon, Text, Utils } from '@azos/shared'
import { Currency } from '@azos/shared/src/utils/currency'
import { useRouter } from 'next/router'

import { FooterResumeProps } from './FooterResume.props'
import { Root } from './FooterResume.styles'

const FooterResume = ({
  parentName = 'footer-resume',
  //
  title = 'Você irá pagar:',
  total,
  label,
  disabled = false,
  canGoBack = false,
  dark,
  //
  onClick,
  frequency,
  paymentAnnual,
  paymentAnnualWithDiscount,
  discount,
  //
  ...rest
}: FooterResumeProps) => {
  const router = useRouter()

  const suffix =
    frequency === CheckoutPaymentFrequency.ANNUAL ? '/ano' : '/ mês'

  const availableDiscount =
    discount?.paymentFrequency === frequency ? discount : undefined

  const periodPrice =
    availableDiscount?.discountPrice ||
    (frequency === CheckoutPaymentFrequency.MONTHLY
      ? total
      : (paymentAnnual as number))

  const formattedPrice = `${Currency(periodPrice).format()}${suffix}`

  return (
    <Root $dark={dark} {...rest}>
      <div>
        <Text variant="body2" component="p">
          {title}
        </Text>
        <div className="resume">
          {availableDiscount && (
            <Text component="del">
              {Utils.currency.format(availableDiscount.originalPrice || 0)}
            </Text>
          )}

          <Text
            parentName={parentName}
            data-impression="true"
            data-value={total.toString()}
            variant="h5"
            component="strong"
          >
            {formattedPrice}
          </Text>
        </div>
      </div>

      <div className="button--group">
        {canGoBack && (
          <Button
            className="back--button"
            color="primary"
            title="Voltar"
            variant="outlined"
            disabled={disabled}
            onClick={() => router.back()}
          >
            <Icon.ArrowLeft />
          </Button>
        )}
        <Button
          className="next--button"
          parentName={parentName}
          color="primary"
          size="large"
          title={label}
          type="submit"
          variant="contained"
          onClick={onClick}
          disabled={disabled}
          loading={disabled}
        >
          {label}
        </Button>
      </div>
    </Root>
  )
}

export default React.memo(FooterResume)
