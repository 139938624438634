import React from 'react'

import Box, { BoxProps } from '@mui/material/Box'

import { Root } from './MainContent.styles'

type MainContentProps = {
  side?: 'left' | 'right'
  aside?: JSX.Element
  asideProps?: BoxProps
  hasAppBar?: boolean
}

const MainContent: React.FCC<MainContentProps> = ({
  children,
  side = 'right',
  aside,
  asideProps = { bgcolor: '#090A0B' },
  hasAppBar = false,
}) => {
  return (
    <Root $hasAppBar={hasAppBar} $side={side}>
      <Box component="aside" {...asideProps}>
        {aside && <div>{aside}</div>}
      </Box>
      <main>{children}</main>
    </Root>
  )
}

export default MainContent
