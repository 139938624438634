import { css, styled, Utils } from '@azos/shared'
import Box, { BoxProps } from '@mui/material/Box'

type DarkProps = BoxProps & {
  $dark?: boolean
}

export const Root = styled(
  Box,
  Utils.transient.transientStyleProps,
)<DarkProps>(
  ({ theme, $dark }) => css`
    background-color: ${$dark ? theme.palette.common.black : 'transparent'};
    border-radius: 8px;
    padding: ${theme.spacing(4)};

    display: flex;
    flex-direction: column;

    & > * {
      flex: 1;

      @media (min-width: ${theme.breakpoints.values.md}px) {
        flex: 0.5;
      }
    }

    & > div {
      color: ${$dark ? theme.palette.common.white : theme.palette.common.black};

      & > strong {
        display: block;
        margin-top: ${theme.spacing(2)};
      }
    }

    .resume {
      display: flex;
      gap: ${theme.spacing(1)};
      align-items: baseline;
    }

    & > .button--group {
      display: flex;
      justify-content: end;
      flex: 1;

      @media (max-width: ${theme.breakpoints.values.md}px) {
        margin-top: ${theme.spacing(3)};
      }

      & > .next--button {
        width: 100%;
        /* max-width: 225px; */
      }

      & > .back--button + .next--button {
        margin-left: ${theme.spacing(2)};
      }

      & > button {
        i {
          color: ${theme.palette.primary.main};
          font-size: 1.85rem;
        }

        &.next--button:disabled {
          background-color: ${theme.palette.primary.dark};
        }
      }
    }

    @media (min-width: ${theme.breakpoints.values.md}px) {
      background-color: transparent;
      padding: 0;

      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  `,
)
