import React from 'react'

import { Icon, Text } from '@azos/shared'
import { Link } from '@presentation/components'

import { Root } from './FAQ.styles'
import { questions } from './utils'

const FAQ: React.VFC = () => {
  return (
    <Root>
      <h1>
        <Link href="https://azos.com.br" title="Azos">
          <Icon.AzosWhite />
        </Link>
      </h1>
      <Text variant="h5" className="content-title">
        Entenda sobre beneficiários
      </Text>
      {questions.map(item => (
        <div key={item.id} className="content-question">
          <Text variant="body1" className="content-question-title">
            {item.question}
          </Text>
          <Text variant="body2" className="content-question-answer">
            {item.answer}
          </Text>
        </div>
      ))}
    </Root>
  )
}

export default FAQ
