import React from 'react'

import { Button, Text } from '@azos/shared'

import { CashbackNotifyProps } from './CashbackNotify.props'
import { Root } from './CashbackNotify.styles'

const CashbackNotify: React.FCC<CashbackNotifyProps> = ({
  onConfirm,
  onIgnore,
}) => {
  return (
    <Root>
      <Text variant="h3">
        Parabéns! <br /> Você garantiu 20% de cashback em conta.
      </Text>
      <Text variant="body1" className="cashback-notify-content__subtitle">
        Complete seus dados para receber o pagamento em até 8 dias.
      </Text>
      <div className="cashback-notify-content__button-group">
        <Button onClick={onConfirm} fullWidth>
          Preencher formulário
        </Button>

        <Button onClick={onIgnore} variant="text" fullWidth>
          Agora não
        </Button>
      </div>
    </Root>
  )
}

export default CashbackNotify
