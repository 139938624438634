import React from 'react'

import { UserConfirmation } from '@presentation/components'

export function withUserConfirmation(WrappedComponent: any) {
  // eslint-disable-next-line react/display-name
  return (props: any) => (
    <React.Fragment>
      <UserConfirmation>
        <WrappedComponent {...props} />
      </UserConfirmation>
    </React.Fragment>
  )
}
