import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100vh;

    .content--user-menu {
      position: fixed;
      top: 30px;
      right: 30px;
      z-index: ${theme.zIndex.appBar};

      ${theme.breakpoints.up('md')} {
        top: 24px;
        right: 56px;
      }

      & > button {
        background: ${theme.palette.common.white};
        border: 1px solid ${theme.palette.grey[200]};
      }
    }
  `,
)
