import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.common.white};
    margin-top: ${theme.spacing(2)};
    border-radius: ${theme.spacing(2)};
    padding: ${theme.spacing(3)};
    box-shadow: ${theme.shadows[2]};

    .popover-content-text {
      margin-bottom: ${theme.spacing(4)};
    }

    & > .modal-content-button {
      width: 100%;
      background: ${theme.palette.background.default};

      i,
      svg {
        font-size: 1.5rem;
      }
    }
  `,
)
