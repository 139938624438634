import { AppError, IProposalConsumer, Services } from '@azos/core'
import { ICoveragesRepository } from '@data/repositories'
import { LoadCoverages2PremiumsUseCase } from '@domain/usecases'

type Params = LoadCoverages2PremiumsUseCase.Params
type Response = LoadCoverages2PremiumsUseCase.Response

export type ILoadCoverages2PremiumsService = Services<Params, Response>

export class LoadCoverages2PremiumsService
  implements ILoadCoverages2PremiumsService
{
  constructor(
    private readonly coveragesRepository: ICoveragesRepository,
    private readonly proposalConsumer: IProposalConsumer,
  ) {}

  async execute(params?: Params): Promise<Response> {
    let proposalId = params?.proposalId

    if (!proposalId) {
      proposalId = this.proposalConsumer.execute()?.proposalId
    }

    if (!proposalId) throw new AppError()

    const coverages = await this.coveragesRepository.loadCoverages2Premiums({
      proposalId,
    })

    return coverages
  }
}
