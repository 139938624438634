import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  border: theme.palette.grey[200],
  borderRadius: '50%',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 48,
  height: 48,
}))
