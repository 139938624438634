import { css, styled, Utils } from '@azos/shared'
import Box, { BoxProps } from '@mui/material/Box'

type RootProps = BoxProps & {
  $disabled: boolean
  $iconColor: string
}

export const Root = styled(
  Box,
  Utils.transient.transientStyleProps,
)<RootProps>(
  ({ theme, $disabled, $iconColor }) =>
    css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      transition: opacity 0.4s;
      opacity: ${$disabled ? 0.4 : 1};
      width: 100%;

      button.control__back-button {
        width: 48px;
      }

      > button {
        height: 100%;

        i {
          color: ${$iconColor};
          font-size: 1.85rem;
        }
      }
      > button + button {
        margin-left: ${theme.spacing(2)};
      }
    `,
)
