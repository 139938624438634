import { css, styled } from '@azos/shared'
import { Box, BoxProps } from '@mui/material'

export const Root = styled(Box)<BoxProps>(
  ({ theme }) => css`
    margin-top: ${theme.spacing(6)};

    p.title > strong {
      color: ${theme.palette.primary.main};
    }

    button.confirmButton {
      flex: 1;
      margin-top: ${theme.spacing(2)};
    }
  `,
)

export const Profile = styled(Box)<BoxProps>(
  ({ theme }) => css`
    border: 1px solid ${theme.palette.grey[200]};
    border-radius: 8px;
    margin: ${theme.spacing(3, 0)};
    padding: ${theme.spacing(3)};

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;

    .avatar__content {
      flex: 1;
      margin-left: ${theme.spacing(3)};
      max-width: calc(100% - 60px - 16px);

      > span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > a {
        font-weight: ${theme.typography.fontWeightBold};
      }
    }
  `,
)
