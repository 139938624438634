import { Utils } from '@azos/shared'
import { CheckoutPixTypes } from '@domain/models'
import * as Yup from 'yup'

export const validationSchema = Yup.object({
  keyType: Yup.string().required('Necessário selecionar uma chave'),
  keyPix: Yup.string()
    .required('Necessário preencher o PIX')
    .when('keyType', {
      is: (field: string) => field === CheckoutPixTypes.cpf,
      then: Yup.string()
        .test({
          name: 'CPF',
          message: 'O CPF não é válido',
          test: value => Utils.validator.validateCpf(value as string),
        })
        .required('O CPF é obrigatório'),
    })
    .when('keyType', {
      is: (field: string) => field === CheckoutPixTypes.email,
      then: Yup.string()
        .email('E-mail inválido')
        .required('O E-mail é obrigatório'),
    })
    .when('keyType', {
      is: (field: string) => field === CheckoutPixTypes.phone,
      then: Yup.string()
        .test({
          name: 'Phone',
          message: 'O número do cartão é inválido',
          test: value => Utils.validator.validatePhone(value as string),
        })
        .required('O número é obrigatório'),
    }),
})
