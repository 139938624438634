import { AppError, Services } from '@azos/core'
import { IDPSRepository } from '@data/repositories'
import {
  GetDPSQuestionnaireUseCase,
  NotifyAbandonedCartUseCase,
} from '@domain/usecases'

type Params = GetDPSQuestionnaireUseCase.Params
type Response = GetDPSQuestionnaireUseCase.Response

export type IGetDPSQuestionnaireService = Services<Params, Response>

export class GetDPSQuestionnaireService implements IGetDPSQuestionnaireService {
  constructor(private readonly DPSRepository: IDPSRepository) {}

  getUrl(params: Params): string {
    return `/dps/${params.proposalId}`
  }

  async execute(params: Params): Promise<Response> {
    const { proposalId } = params
    return await this.DPSRepository.getDPSQuestionnaire({
      proposalId,
    })
  }
}
