import React from 'react'

import { Text } from '@azos/shared'

const Subtitle: React.FCC = ({ children, ...rest }) => {
  return (
    <Text variant="h3" component="h2" className="subtitle" {...rest}>
      {children}
    </Text>
  )
}

export default Subtitle
