import { HttpClient, UnexpectedError } from '@azos/core'
import { LoadBillingDateUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IProposalsRepository = LoadBillingDateUseCase

export class ProposalsRepository implements IProposalsRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async loadBillingDate({
    proposalId,
    billingDay,
  }: LoadBillingDateUseCase.Params): Promise<LoadBillingDateUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/proposals/${proposalId}/simulate-billing-date`,
      method: 'post',
      body: {
        billingDay,
      },
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as LoadBillingDateUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
