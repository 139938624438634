import { css } from '@azos/shared'
import { transientStyleProps } from '@azos/shared/src/utils/transient'
import { styled } from '@mui/material/styles'

import { BadgeVariant } from './Badge.props'

const getColor = (theme: any) => (variant: BadgeVariant) => {
  const hash = {
    primary: {
      background: '#97006F',
      color: '#FFD9FF',
    },
    secondary: {
      background: '#33287F',
      color: '#CEC7FF',
    },
    error: {
      background: '#FFB18E',
      color: '#791407',
    },
    warning: {
      background: '#FCDF84',
      color: '#5F4800',
    },
    success: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.dark,
    },
    light: {
      background: theme.palette.grey[200],
      color: theme.palette.grey[400],
    },
  }
  return hash[variant]
}

type RootProps = {
  $variant: BadgeVariant
}

export const Root = styled(
  'span',
  transientStyleProps,
)<RootProps>(({ theme, $variant }) => {
  const colors = getColor(theme)($variant)

  return css`
    background: ${colors.background};
    border-radius: 4px;
    color: ${colors.color};
    font-size: 12px;
    font-weight: ${theme.typography.fontWeightBold};
    padding: 4px 12px;
    white-space: nowrap;
    display: inline-flex;
    gap: 0.4375rem;
    align-items: center;

    [class^='icon-'] {
      font-size: 14px;
    }
  `
})
