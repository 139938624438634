export type DPSQuestionnaire = {
  questions: DPSQuestion[]
}

export type DPSQuestion = {
  questionId: string
  //
  title: string
  description?: string | null
  text: string
  multiple: boolean
  //
  choices: DPSChoice[]
  //
  exclusiveChoice?: string | null
  trigger?: {
    questionId: string
    choices: string[]
  } | null
}

export type DPSChoice = {
  id: string
  text: string
  exclusive?: boolean
}

export enum FieldType {
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
  OPEN_TEXT = 'open_text',
  SELECT_CHOICE = 'select_choice',
  COUNTRY_CHOICE = 'country_choice',
}

export type CreateDPSParams = {
  answers: { [key: string]: string[] }
  proposalId: string
  hasIllnessInjury: boolean
  illnessInjuryDescription?: string
  diagnosedHasMoreThanFiveYears?: boolean
  stillInTreatment?: boolean
  treatmentAndUsedMedications?: string
  diseaseSequel?: string
  illnessInvestigation?: boolean
  investigationReason?: string
}
