import React from 'react'

import { useMediaQuery } from '@azos/shared'

import { CashbackSuccess } from '../CashbackSuccess'
import { CashbackSuccessCardProps } from './CashbackSuccessCard.props'
import { Modal } from './CashbackSuccessCard.styles'

const CashbackSuccessCard: React.FCC<CashbackSuccessCardProps> = ({
  onDone,
  pixValue,
  ...props
}) => {
  const { isMobile } = useMediaQuery()

  return (
    <Modal {...props} variant="drawer" showCloseButton={!isMobile}>
      <CashbackSuccess onDone={onDone} pixValue={pixValue} />
    </Modal>
  )
}

export default CashbackSuccessCard
