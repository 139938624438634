import { StepLabel as BaseStepLabel, StepLabelProps } from '@azos/shared'
import BaseStep from '@mui/material/Step'
import BaseStepContent, { StepContentProps } from '@mui/material/StepContent'
import BaseStepper from '@mui/material/Stepper'
import { styled } from '@mui/material/styles'

export const Stepper = styled(BaseStepper)(() => ({
  padding: 0,
  margin: 0,
  width: '100%',
}))

export const Step = styled(BaseStep)(() => null)

export const StepLabel = styled(BaseStepLabel)<StepLabelProps>(({ theme }) => ({
  '.MuiStepLabel-label': {
    color: '#91959C',
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: theme.typography.fontFamily,
  },
  '.MuiStepLabel-label.Mui-active': {
    color: '#FFF',
  },
}))

export const StepContent = styled(BaseStepContent)<StepContentProps>(
  ({ theme }) => ({
    borderLeftColor: '#FFF',
    color: theme.palette.grey[300],
    marginLeft: 25,

    '& > div': {
      marginLeft: 15,
    },
  }),
)
