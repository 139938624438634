import React from 'react'

import { Button, Icon, theme } from '@azos/shared'
import { Utils } from '@data/utils'
import { useFormikContext } from 'formik'

import { ControlProps } from './Control.props'
import { Root } from './Control.styles'

const ACTIONS = {
  BUTTON_BACK: Utils.actions.general.BUTTON_BACK_CONTROL,
  BUTTON_NEXT: Utils.actions.general.BUTTON_NEXT_CONTROL,
}

const Control: React.FCC<ControlProps> = ({
  onPrev,
  //
  disabled = false,
  disabledPrevButton = false,
  disabledNextButton = false,
  requiredValid = true,
  hideNextButton = false,
  //
  textNextButton = 'Continuar',
  //
  ...rest
}) => {
  const { isValid: formIsValid, isSubmitting } = useFormikContext<any>()

  const isAllDisabled = React.useMemo<boolean>(
    () => disabled || isSubmitting,
    [disabled, isSubmitting],
  )

  const isDisabledPrevButton = React.useMemo(() => {
    return disabledPrevButton || isAllDisabled
  }, [disabledPrevButton, isAllDisabled])

  const isDisabledNextButton = React.useMemo(() => {
    const isRequiredValid = requiredValid && !formIsValid
    return disabledNextButton || isRequiredValid || isAllDisabled
  }, [disabledNextButton, formIsValid, isAllDisabled, requiredValid])

  const iconColor = disabledPrevButton
    ? theme.palette.grey[200]
    : theme.palette.primary.main

  return (
    <Root $disabled={disabled} $iconColor={iconColor} {...rest}>
      <Button
        className="control__back-button"
        color="primary"
        variant="outlined"
        size="large"
        type="button"
        onClick={onPrev}
        disabled={isDisabledPrevButton}
        parentName={ACTIONS.BUTTON_BACK}
        fullWidth
      >
        <Icon.ArrowLeft />
      </Button>

      {!hideNextButton && (
        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          disabled={isDisabledNextButton}
          parentName={ACTIONS.BUTTON_NEXT}
          fullWidth
        >
          {textNextButton}
        </Button>
      )}
    </Root>
  )
}

export default Control
