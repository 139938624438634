import { HttpClient, UnexpectedError } from '@azos/core'
import { Contract } from '@domain/models'
import { CreateCashbackUseCase, CreateContractUseCase } from '@domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type ICheckoutRepository = CreateCashbackUseCase & CreateContractUseCase

export class CheckoutRepository implements ICheckoutRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async createContract(
    params: CreateContractUseCase.Params,
  ): Promise<Contract> {
    const httpResponse = await this.httpClient.request({
      url: `/checkout`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
      case HttpStatusCode.CREATED:
        return response as CreateContractUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async createCashback(
    params: CreateCashbackUseCase.Params,
  ): Promise<CreateCashbackUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/checkout/cashback`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CreateCashbackUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }
}
