import {
  css,
  styled,
  Modal as SharedModal,
  ModalProps as SharedModalProps,
} from '@azos/shared'

export const Modal = styled(SharedModal)<SharedModalProps>(
  ({ maxWidth, theme }) => css`
    .modal-content {
      @media (min-width: ${theme.breakpoints.values.md}px) {
        .modal-header {
          margin: ${theme.spacing(6, 3, 4, 6)};
        }

        .modal-content-wrapper {
          margin: ${theme.spacing(0, 3, 3, 3)};
        }

        button {
          max-width: 320px;
        }
      }
    }
  `,
)
