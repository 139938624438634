import React from 'react'

import { useWizard } from '@presentation/components'

import { ProgressBar } from './Progress.styles'

const Progress = () => {
  const { step, max } = useWizard()

  const progress = React.useMemo<number>(
    () => Math.floor((step / max) * 100),
    [max, step],
  )

  return <ProgressBar color="primary" variant="determinate" value={progress} />
}

export default Progress
