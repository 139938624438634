import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;

    .content {
      &-title {
        color: ${theme.palette.common.white};
        margin: 3rem 0;
      }

      &-question {
        &:not(:first-of-type) {
          margin-top: 2rem;
        }

        &-title {
          color: ${theme.palette.grey[100]};
        }

        &-answer {
          margin-top: ${theme.spacing(0)};
          color: ${theme.palette.grey[200]};
        }
      }
    }
  `,
)
