const growthbookConfig = {
  apiHost: 'https://cdn.growthbook.io',
  clientKey: {
    staging: 'sdk-FP2TsGCskqRD3c7r',
    production: 'sdk-pcgAJDGvq6A6XZtJ',
  },
  enableDevMode: process.env.NODE_ENV === 'development',
}

export const GROWTHBOOK_EXPERIMENTS = {
  COBERTURA_MAXIMA: 'cobertura-maxima',
}

export default growthbookConfig
