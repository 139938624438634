import { css, styled, Utils } from '@azos/shared'
import { Form as FormikForm } from 'formik'

type FormProps = {
  $onTransition: boolean
}

export const Form = styled(
  FormikForm,
  Utils.transient.transientStyleProps,
)<FormProps>(
  ({ theme, $onTransition }) => css`
    overflow-y: ${$onTransition ? 'hidden' : 'visible'};

    .control-container {
      margin: 0 auto;
      width: 100%;

      padding: ${theme.spacing(0, 4, 4, 4)};
      ${theme.breakpoints.up('sm')} {
        padding: ${theme.spacing(0, 5, 5, 5)};
      }

      ${theme.breakpoints.up('md')} {
        max-width: 80%;
      }
    }
  `,
)
