import React from 'react'

import { useRouter } from 'next/router'

import { useWizard } from '../../providers'

type WizardRouterProps = {
  initialStep?: number
}

const WizardRouter: React.FCC<WizardRouterProps> = ({
  children,
  initialStep = 1,
}) => {
  const router = useRouter()
  const { step, max, setStep } = useWizard()
  const [loaded, setLoaded] = React.useState<boolean>(false)
  const changedRoute = React.useRef<boolean>(false)

  const stepByQuery = React.useMemo<number>(() => {
    const stepNum = initialStep > max ? 1 : initialStep
    return Math.max(Math.min(stepNum, max), 1)
  }, [max, initialStep])

  React.useEffect(() => {
    if (!router.isReady || loaded) return

    const stepValue = stepByQuery - 1
    setLoaded(true)

    if (stepValue !== step) {
      setStep(stepValue)
    }
  }, [loaded, router.isReady, setStep, step, stepByQuery])

  React.useEffect(() => {
    if (!router.isReady || !loaded) return

    const url = router.asPath
    const stepValue = step + 1

    delete router.query.s

    let route = `${router.pathname}?s=${stepValue}`

    const isInitialRoute = stepValue === initialStep

    if (
      Object.entries(router.query).length &&
      isInitialRoute &&
      !changedRoute.current
    ) {
      const qs = Object.entries(router.query).reduce(
        (acc, [key, value]) => (acc += `&${key}=${value}`),
        '',
      )

      route = `${route}${qs}`
    }

    if (url !== route) {
      changedRoute.current = true
      router.replace(url, route, {
        shallow: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, router.isReady, step])

  return <>{children}</>
}

export default WizardRouter
