import React from 'react'

import { Icon } from '@azos/shared'
import { Link, Stepper } from '@presentation/components'

import { steps } from './Section.config'
import { SectionProps } from './Section.props'
import { Root, Title } from './Section.styles'

const Section = ({ section }: SectionProps) => {
  return (
    <Root>
      <h1>
        <Link href="https://azos.com.br" title="Azos">
          <Icon.AzosWhite />
        </Link>
      </h1>

      <Title>Passo a passo</Title>

      <Stepper steps={steps} step={section} />
    </Root>
  )
}

export default Section
