import { styled, css } from '@azos/shared'
import Box, { BoxProps } from '@mui/material/Box'

export type RootProps = BoxProps & {
  $direction: 'forward' | 'backward'
  $stopped: boolean
}

export const Root = styled(Box)<RootProps>(
  ({ theme, $stopped, $direction }) => css`
    position: absolute;
    pointer-events: none;
    min-height: 100vh;
    height: 100%;

    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    display: flex;
    align-items: flex-start;
    justify-content: center;

    @keyframes bgMotion {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }

    @keyframes fadeInFromTopToBottom {
      0% {
        transform: translateY(-10%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    @keyframes fadeInFromBottomToTop {
      0% {
        transform: translateY(10%);
        opacity: 0;
      }
      100% {
        transform: translateY(0%);
        opacity: 1;
      }
    }

    div.loading__bg {
      background-color: ${theme.palette.common.black};
      height: 100%;

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
      z-index: 0;

      animation-iteration-count: 1;
      animation-fill-mode: both;
      animation-duration: 0.5s;
      animation-name: bgMotion;

      ${$stopped &&
      css`
        animation-iteration-count: 0;
      `}
      ${$direction == 'forward' &&
      css`
        animation-delay: 0;
        animation-direction: normal;
        width: 0;
      `}
      ${$direction == 'backward' &&
      css`
        animation-delay: 0.5s;
        animation-direction: reverse;
        width: 100%;

        ${theme.breakpoints.down('md')} {
          left: auto;
          right: 0;
        }
      `}
    }

    div.loading__container {
      padding: ${theme.spacing(4)};
      position: relative;
      height: 100vh;
      width: 100%;
      z-index: 1;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    div.loading__content {
      position: relative;
      text-align: center;
      width: 100%;
      max-width: 400px;

      & .text {
        color: ${theme.palette.common.white};
        animation-iteration-count: 1;
        animation-fill-mode: both;
        animation-duration: 0.5s;

        ${$stopped &&
        css`
          animation-iteration-count: 0;
        `}
        ${$direction == 'forward' &&
        css`
          animation-delay: 0.5s;
          animation-direction: normal;
          opacity: 0;
        `}
        ${$direction == 'backward' &&
        css`
          animation-delay: 0;
          animation-direction: reverse;
          opacity: 1;
        `}
      }
      & .heading {
        animation-name: fadeInFromTopToBottom;
      }
      & .description {
        animation-name: fadeInFromBottomToTop;
        margin-top: ${theme.spacing(4)};
      }
    }
  `,
)
