import { css, styled } from '@azos/shared'
import { Form as FormikForm } from 'formik'

export const Form = styled(FormikForm)(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      justify-content: normal;
      margin-top: ${theme.spacing(6)};

      button {
        margin-top: 100px;
      }
    }

    .cashback-form {
      &__content {
        & > div {
          margin-top: ${theme.spacing(3)};
        }
      }

      &__subtitle {
        margin: ${theme.spacing(3, 0, 3)};
        color: ${theme.palette.grey[400]};
      }

      button {
        height: 48px;
      }
    }
  `,
)
