import { Services } from '@azos/core'
import { ICheckoutRepository } from '@data/repositories'
import { CreateCashbackUseCase } from '@domain/usecases'

type Params = CreateCashbackUseCase.Params
type Response = CreateCashbackUseCase.Response

export type ICreateCashbackService = Services<Params, Response>

export class CreateCashbackService implements ICreateCashbackService {
  constructor(private readonly checkoutRepository: ICheckoutRepository) {}

  async execute(params: Params): Promise<Response> {
    return await this.checkoutRepository.createCashback(params)
  }
}
