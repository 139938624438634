export const getQuotationExpress = (value: string) => {
  const decoded = Buffer.from(value, 'base64').toString() as any
  const values = JSON.parse(decoded)

  if (
    !!values.birthDate &&
    !!values.gender &&
    !!values.height &&
    !!values.weight &&
    !!values.isSmoker &&
    !!values.price &&
    !!values.widgetOrigin
  ) {
    return {
      birth_date: values.birthDate,
      gender: values.gender,
      height: values.height,
      weight: values.weight,
      is_smoker: values.isSmoker === 'true',
      price: values.price,
      widgetOrigin: values.widgetOrigin,
    }
  }

  return null
}
