import {
  makeCheckBillingStatusService,
  makeCoreCheckoutRepository,
  makeLoadInvoiceDataService,
  makeProposalConsumer,
  makeProposalProducer,
} from '@azos/core'
import {
  CreateCashbackService,
  CreateContractService,
  CreatePaymentService,
} from '@data/services'
import { makeCheckoutProvider } from '@main/factories/infra'

import { makeCreditcardMapper } from '../mappers/checkout'
import { makeCheckoutRepository } from '../repositories'

export const makeCreateCashbackService = () =>
  new CreateCashbackService(makeCheckoutRepository())

export const makeCreateContractService = () =>
  new CreateContractService(
    makeCheckoutProvider,
    makeCheckoutRepository(),
    makeCreditcardMapper(),
  )

export const makeCreatePaymentService = () =>
  new CreatePaymentService(
    makeCreateContractService(),
    makeCheckBillingStatusService(),
    makeCoreCheckoutRepository(),
    makeLoadInvoiceDataService(),
    makeProposalConsumer(),
    makeProposalProducer(),
  )
