import { makeAxiosHttpClient } from '@azos/core'
import { DPSRepository } from '@data/repositories'
import { CreateDPSService, GetDPSQuestionnaireService } from '@data/services'

import { makeDPSRepository } from '../repositories'

export const makeGetDPSQuestionnaireService = () =>
  new GetDPSQuestionnaireService(makeDPSRepository())

export const makeCreateDPSService = () =>
  new CreateDPSService(makeDPSRepository())

export const createDPSQuestionnaire = params =>
  new DPSRepository(makeAxiosHttpClient()).createDPSQuestionnaire(params)

export const generateDPS3Score = params =>
  new DPSRepository(makeAxiosHttpClient()).generateDPS3Score(params)
