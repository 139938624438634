import { makeProposalConsumer } from '@azos/core'
import { LoadCoverages2PremiumsService } from '@data/services'

import { makeCoveragesRepository } from '../repositories'

export const makeLoadCoverages2PremiumsService = () =>
  new LoadCoverages2PremiumsService(
    makeCoveragesRepository(),
    makeProposalConsumer(),
  )
