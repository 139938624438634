import React from 'react'

import { useMediaQuery } from '@azos/shared'

import { CashbackNotify } from '../CashbackNotify'
import { CashbackNotifyCardProps } from './CashbackNotifyCard.props'
import { Modal } from './CashbackNotifyCard.styles'

const CashbackNotifyCard: React.FCC<CashbackNotifyCardProps> = ({
  onConfirm,
  onIgnore,
  ...props
}) => {
  const { isMobile } = useMediaQuery()
  return (
    <Modal
      {...props}
      variant={isMobile ? 'bottomDrawer' : 'modal'}
      maxWidth={isMobile ? undefined : '510px'}
      showCloseButton={!!isMobile}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <CashbackNotify onConfirm={onConfirm} onIgnore={onIgnore} />
    </Modal>
  )
}

export default CashbackNotifyCard
