import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector'
import { styled } from '@mui/material/styles'

export const StepperConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: 25,

  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    backgroundColor: 'orange',
    borderColor: 'orange',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: 'blue',
      borderColor: 'blue',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#FFF',
      borderColor: '#FFF',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 1,
    backgroundColor: '#505969',
    borderColor: '#505969',
  },
}))
