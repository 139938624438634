import { CheckoutPixTypes } from '@domain/models'

export const initialValues = {
  keyType: '',
  keyPix: '',
}

export const pixTypeOptions = [
  {
    label: 'CPF',
    value: CheckoutPixTypes.cpf,
  },
  {
    label: 'Email',
    value: CheckoutPixTypes.email,
  },
  {
    label: 'Telefone',
    value: CheckoutPixTypes.phone,
  },
  {
    label: 'Outro',
    value: CheckoutPixTypes.others,
  },
]
