import React from 'react'

import { Modal, useMediaQuery, withModal } from '@azos/shared'

import CashbackForm from '../CashbackForm/CashbackForm'
import { CashbackFormCardProps } from './CashbackFormCard.props'

const CashbackFormCard: React.FCC<CashbackFormCardProps> = ({
  onSubmit,
  ...props
}) => {
  return (
    <Modal
      {...props}
      variant="drawer"
      showCloseButton={true}
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <CashbackForm onSubmit={onSubmit} />
    </Modal>
  )
}

export default withModal(CashbackFormCard)
