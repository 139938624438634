const actions = {
  general: {
    // COMMON
    CALLPAGE: 'b2c-callpage',

    CALLPAGE_MODAL: 'b2c-callpage-modal',
    CALLPAGE_POPPER: 'b2c-callpage-popper',

    IMPRESSION: 'true',
    // CONTROLS
    BUTTON_BACK_CONTROL: 'b2c-previous-step',
    BUTTON_NEXT_CONTROL: 'b2c-next-step',
    // RESUME
    TOTAL: 'b2c-total',

    RADIO_HAS_INSURANCE: 'b2c-radio-has-insurance',
    WRAPPER_HAS_INSURANCE: 'b2c-wrapper-has-insurance',
    // VIEWERS
    VIEWER_MOBILE_STEP: 'b2c-mobile-steps-viewer',
    VIEWER_COVERAGE_SELECT: 'b2c-steps-viewer-2',
    VIEWER_HEALTH_CHECK: 'b2c-steps-viewer-4',
    VIEWER_PERSONAL_DATA: 'b2c-steps-viewer-1',
    VIEWER_SIMULATION_RESUME: 'b2c-steps-viewer-3',

    BUTTON_UNEXPECTED_ERROR: 'b2c-unexpected-error',
  },
  simulation: {
    INPUT_BIRTHDATE: 'b2c-birthdate',
    INPUT_PROFESSION_AUTOCOMPLETE: 'b2c-profession',
    // MODAL
    MODAL_OPEN_GENDER: 'b2c-gender-info-modal',
    MODAL_OPEN_SMOKER: 'b2c-smoker-info-modal',
    MODAL_OPEN_PROFESSION: 'b2c-profession-info-modal',
    MODAL_OPEN_SALARY: 'b2c-salary-info-modal',
    B2C_TYPE: 'b2c',
    // INPUTS
    BUTTON_GENDER: 'b2c-gender',
    BUTTON_HAS_INSURANCE: 'b2c-has-insurance',
    BUTTON_SALARY: 'b2c-rent',
    BUTTON_SMOKER: 'b2c-smoker',
    BUTTON_START_HIRING: 'b2c-start-hiring',
  },
  dps: {
    INPUT_OTHER_DISEASES: 'b2c-other-diseases',
    RESUME_CONTACT: 'b2c-dps-resume-contact',
  },
  calculator: {
    CARD_PRODUCT_LIFE_INSURANCE: 'b2c-life-insurance',
    CARD_PRODUCT_INCIDENT: 'b2c-incident',
    CARD_PRODUCT_ILLNESS: 'b2c-illness',
  },
  resume: {
    FUNERAL: 'b2c-funeral',
    RESUME_CONTACT: 'b2c-resume-contact',
  },
  beneficiaries: {
    VIEWER_HEADER: 'b2c-beneficiaries-header',

    // BENEFICIARY
    INPUT_BENEFICIARY_DEFINE: 'b2c-beneficiary-define',
    // SELECTION
    BUTTON_BENEFICIARY_ADD: 'b2c-beneficiary-add',
    BUTTON_BENEFICIARY_ITEM: 'b2c-beneficiary-item',
    // REGISTER
    INPUT_BENEFICIARY_REGISTER: 'b2c-beneficiary-register',
    BUTTON_BENEFICIARY_REMOVE: 'b2c-beneficiary-remove',
    BUTTON_BENEFICIARY_SAVE: 'b2c-beneficiary-save',
    // DISTRIBUTE
    BUTTON_BENEFICIARY_PERCENTAGE: 'b2c-beneficiary-percentage',
    COUNTER_BENEFICIARY_PERCENTAGE: 'b2c-counter-beneficiary-percentage',
  },
  checkout: {
    VIEWER_HEADER: 'b2c-checkout-header',

    BUTTON_PAYMENT_TYPE: 'b2c-button-payment-type',

    BOLETO_VIEWER_PAY_DATA: 'b2c-checkout-boleto-viewer-1',
    BOLETO_VIEWER_CONFIRM_PAYMENT: 'b2c-checkout-boleto-viewer-2',
    BOLETO_VIEWER_HOLD_ANALYSIS: 'b2c-checkout-boleto-viewer-3',
    BOLETO_VIEWER_APOLICE_DELIVERED: 'b2c-checkout-boleto-viewer-4',

    PIX_VIEWER_COPY_PIX_CODE: 'b2c-checkout-pix-viewer-1',
    PIX_VIEWER_OPEN_BANK_APP: 'b2c-checkout-pix-viewer-2',
    PIX_VIEWER_CLICK_PIX_COPY: 'b2c-checkout-pix-viewer-3',
    PIX_VIEWER_PAYMENT_ACTION: 'b2c-checkout-pix-viewer-4',
    PIX_VIEWER_REDIRECT_AZOS: 'b2c-checkout-pix-viewer-5',

    //pix status
    PIX_BLOCKED_REDIRECT_SIMULATION:
      'b2c-checkout-pix-blocked-redirect-simulation',

    PIX_ERROR_RETRY_PAYMENT: 'b2c-checkout-pix-error-retry-payment',
    PIX_ERROR_CHANGE_PAYMENT_TYPE: 'b2c-checkout-pix-error-change-payment-type',

    PIX_TIMEOUT_COPY_PIX: 'b2c-checkout-pix-timeout-copy-pix',
    PIX_TIMEOUT_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-pix-timeout-check-proposal-status',

    PIX_PAID: 'b2c-checkout-pix-paid',
    PIX_PAID_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-pix-paid-check-proposal-status',

    PIX_PROCESS_COPY_PIX: 'b2c-checkout-pix-process-copy-pix',
    PIX_PROCESS_CHANGE_PAYMENT_TYPE:
      'b2c-checkout-pix-process-change-payment-type',

    // credit card status
    CREDIT_CARD_BLOCKED_REDIRECT_SIMULATION:
      'bix-checkout-credit-card-blocked-redirect-simulation',

    CREDIT_CARD_ERROR_RETRY_PAYMENT:
      'b2c-checkout-credit-card-error-retry-payment',
    CREDIT_CARD_ERROR_CHANGE_PAYMENT_TYPE:
      'b2c-checkout-credit-card-error-change-payment-type',

    CREDIT_CARD_TIMEOUT_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-credit-card-timeout-check-proposal-status',

    CREDIT_CARD_PAID_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-credit-card-paid-check-proposal-status',
    CREDIT_CARD_PAID: 'b2c-checkout-credit-card-paid',

    // boleto status
    BOLETO_BLOCKED_REDIRECT_SIMULATION:
      'b2c-checkout-boleto-blocked-redirect-simulation',

    BOLETO_ERROR_RETRY_PAYMENT: 'b2c-checkout-boleto-error-retry-payment',
    BOLETO_ERROR_CHANGE_PAYMENT_TYPE:
      'b2c-checkout-boleto-error-change-payment-type',

    BOLETO_PAID_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-boleto-paid-check-proposal-status',
    BOLETO_PAID: 'b2c-checkout-boleto-paid',

    BOLETO_PROCESS_COPY_CODE: 'b2c-checkout-boleto-process-copy-code',
    BOLETO_PROCESS_CHECK_PROPOSAL_STATUS:
      'b2c-checkout-boleto-process-check-proposal-status',

    VIEWER_ANALYSIS: 'b2c-checkout-viewer-1',
    VIEWER_APPROVED: 'b2c-checkout-viewer-2',
    VIEWER_SHARE: 'b2c-checkout-viewer-3',
  },
}

export default actions
