import React from 'react'

import Typography, { TypographyProps } from '@mui/material/Typography'

type StepperTextProps = TypographyProps & {
  label: React.ReactNode
  title?: string
}

const StepperText: React.FCC<StepperTextProps> = ({
  label,
  title,
  ...rest
}) => {
  return (
    <Typography variant="h6" component="span" {...rest}>
      {label}
    </Typography>
  )
}

export { StepperText }
