import React from 'react'

import { useNotify } from '@azos/shared'
import { makeCreateCashbackService } from '@main/factories/services'
import {
  CashbackFormCard,
  CashbackFormValues,
  CashbackNotifyCard,
  CashbackSuccessCard,
} from '@presentation/components'
import { useTimeout } from '@presentation/hooks/useTimeout'
import { useCashback } from '@presentation/providers/CashbackProvider'

import { CashbackWrapperProps } from './CashbackWrapper.props'

const CASHBACK_MODAL_OPEN_DELAY = 3000

const createCashbackService = makeCreateCashbackService()

const CashbackWrapper: React.FCC<CashbackWrapperProps> = ({
  children,
  proposalId,
  onDone,
  onSuccessEvent,
}) => {
  const { addNotify } = useNotify()
  const { isAvailable: isAvailableCashback } = useCashback()

  const [isCashbackFormOpen, setIsCashbackFormOpen] = React.useState(false)
  const [isCashbackNotifyOpen, setIsCashbackNotifyOpen] = React.useState(false)
  const [isCashbackSuccessOpen, setIsCashbackSuccessOpen] =
    React.useState(false)

  const [pixValue, setPixValue] = React.useState('')

  useTimeout(() => {
    if (isAvailableCashback) {
      setIsCashbackNotifyOpen(true)
    }
  }, CASHBACK_MODAL_OPEN_DELAY)

  const handleSubmit = async ({ keyType, keyPix }: CashbackFormValues) => {
    try {
      await createCashbackService.execute({
        keyPix,
        keyType,
        proposalId,
      })

      onSuccessEvent?.()

      setPixValue(keyPix)
      setIsCashbackSuccessOpen(true)
    } catch (error) {
      addNotify({
        message:
          'Não foi possível enviar os dados, tente novamente ou entre em contato com o atendimento.',
        status: 'error',
      })
    }
  }

  const handleOpenForm = () => {
    setIsCashbackFormOpen(true)
    setIsCashbackNotifyOpen(false)
  }

  return (
    <>
      {children}
      <CashbackNotifyCard
        open={isCashbackNotifyOpen}
        onClose={() => setIsCashbackNotifyOpen(false)}
        onConfirm={handleOpenForm}
        onIgnore={() => setIsCashbackNotifyOpen(false)}
      />
      <CashbackFormCard
        open={isCashbackFormOpen}
        onClose={() => setIsCashbackFormOpen(false)}
        onSubmit={handleSubmit}
      />
      <CashbackSuccessCard
        open={isCashbackSuccessOpen}
        onClose={() => {
          setIsCashbackSuccessOpen(false)
          setIsCashbackFormOpen(false)
        }}
        onDone={onDone}
        pixValue={pixValue}
      />
    </>
  )
}

export default CashbackWrapper
