import { css, styled } from '@azos/shared'
import Box, { BoxProps } from '@mui/material/Box'

type RootProps = BoxProps & {
  $hasAppBar: boolean
  $side: 'left' | 'right'
}

export const Root = styled(Box)<RootProps>(
  ({ theme, $hasAppBar, $side }) => css`
    display: grid;
    height: 100%;

    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'main';

    ${theme.breakpoints.up('md')} {
      ${$side === 'right' &&
      css`
        grid-template-columns: min(30%, 400px) 1fr;
        grid-template-areas: 'aside main';
      `}
      ${$side === 'left' &&
      css`
        grid-template-columns: 1fr min(30%, 400px);
        grid-template-areas: 'main aside';
      `}
    }

    aside {
      grid-area: aside;

      ${theme.breakpoints.down('md')} {
        display: none;
      }

      > div {
        padding: ${theme.spacing(5)};
        position: sticky;
        top: 0;
      }
    }

    main {
      grid-area: main;

      background-color: ${theme.palette.background.default};
      position: relative;
      min-height: ${$hasAppBar ? 'calc(100vh - 80px)' : 'auto'};
    }
  `,
)
