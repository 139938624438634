import { HttpClient } from '@azos/core'
import {
  LoadBillingDateService,
  LoadFinalQuotationsService,
} from '@data/services'

import {
  makeFinalQuotationsRepository,
  makeProposalsRepository,
} from '../repositories'

export const makeLoadFinalQuotationsService = (httpClient?: HttpClient) =>
  new LoadFinalQuotationsService(makeFinalQuotationsRepository(httpClient))

export const makeLoadBillingDateService = (httpClient?: HttpClient) =>
  new LoadBillingDateService(makeProposalsRepository(httpClient))
