import React from 'react'
import { animated, useSpring } from 'react-spring'

import { ButtonSelect, Text, Utils } from '@azos/shared'
import ClickAwayListener from '@mui/material/ClickAwayListener'

import { PopperContentProps } from './PopperContent.props'
import { Root } from './PopperContent.styles'

const PopperContent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  PopperContentProps
> = (
  { onEnter, onExited, onCallPageClick, in: open, onClose, parentName },
  ref,
) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translateY(-10%)' },
    to: {
      opacity: open ? 1 : 0,
      transform: open ? 'translateY(0%)' : 'translateY(-10%)',
    },
    config: { duration: 150 },
    onStart: () => {
      if (open && onEnter) {
        onEnter()
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited()
      }
    },
  })

  return (
    <ClickAwayListener onClickAway={onClose}>
      <animated.div ref={ref} style={style}>
        <Root
          data-action={Utils.dataNames.renderDataName('popper', parentName)}
        >
          <Text variant="h5" className="popover-content-text">
            Precisa de ajuda?
          </Text>
          <ButtonSelect
            startIcon={<i className="icon-phone" />}
            className="modal-content-button"
            title="Contrate pelo telefone"
            description="Ligamos em 45 segundos"
            onClick={onCallPageClick}
          />
        </Root>
      </animated.div>
    </ClickAwayListener>
  )
}

export default React.forwardRef(PopperContent)
