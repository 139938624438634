import React from 'react'

import { Text } from '@azos/shared'

import { SimulationLoadingProps } from './SimulationLoading.props'
import { Root } from './SimulationLoading.styles'

const LoadingCoverages: React.VFC<SimulationLoadingProps> = ({
  title = '💚 Perfeito!',
  description = 'Aguarde só um instante. Estamos selecionando os seguros que se encaixam no seu perfil.',
  reverse = false,
  stopped = false,
}) => {
  return (
    <Root $stopped={stopped} $direction={reverse ? 'backward' : 'forward'}>
      <div className="loading__container">
        <div className="loading__content">
          <Text className="text heading" variant="h2" component="h2">
            {title}
          </Text>
          {description && (
            <Text className="text description" variant="body1">
              {description}
            </Text>
          )}
        </div>
      </div>
      <div className="loading__bg"></div>
    </Root>
  )
}

export default LoadingCoverages
