import { SWRServices } from '@azos/core'
import { IQuotationsRepository } from '@data/repositories'
import { LoadQuotationByIdUseCase } from '@domain/usecases'

type Params = LoadQuotationByIdUseCase.Params
type Response = LoadQuotationByIdUseCase.Response

export type ILoadQuotationByIdService = SWRServices<Params, Response>

export class LoadQuotationByIdService implements ILoadQuotationByIdService {
  constructor(private readonly quotationsRepository: IQuotationsRepository) {}

  getUrl(quotationId: string): string {
    return `/quotation/${quotationId}`
  }

  async execute(
    quotationId: string,
  ): Promise<LoadQuotationByIdUseCase.Response> {
    const response = await this.quotationsRepository.loadById(quotationId)

    return response
  }
}
