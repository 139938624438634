import { Typography, TypographyProps } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'

export const Root = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const Title = styled(Typography)<TypographyProps<any>>(({ theme }) => ({
  color: theme.palette.common.white,
  marginTop: 80,
  marginBottom: 50,
}))

Title.defaultProps = {
  variant: 'h5',
  component: 'strong',
}
