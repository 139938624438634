export const questions = [
  {
    id: 1,
    question: 'Posso colocar um menor de idade como beneficiário?',
    answer:
      'Sim! Lembrando que caso aconteça algo com o titular do seguro o tutor deverá criar uma conta no nome do beneficiário para ele receber o valor',
  },
  {
    id: 2,
    question: 'Posso escolher quanto fica para cada beneficiário?',
    answer:
      'Sim! E o mais legal é que caso você queira alterar e as porcentagens ou os beneficiários, é só acessar a sua conta em nosso site.',
  },
  {
    id: 3,
    question: 'Posso mudar o beneficiário?',
    answer:
      'Pode sim. Para alterar beneficiários basta entrar em sua conta. Ao fazer o login é só selecionar sua apólice e fazer a alteração.',
  },
  {
    id: 4,
    question:
      'Se acontecer algo comigo e com o beneficiário para quem fica o dinheiro?',
    answer:
      'Uma perícia será feita e se constatado que o beneficiário faleceu primeiro, seus herdeiros legais serão indenizados. Caso contrário, os herdeiros legais da pessoa titular terão o direito ao valor da cobertura.',
  },
]
