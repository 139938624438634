import LinearProgress from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,

  [theme.breakpoints.up('md')]: {
    position: 'absolute',
  },
}))
