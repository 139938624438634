import { css, styled } from '@azos/shared'

export const Root = styled('div')(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    @media (min-width: ${theme.breakpoints.values.md}px) {
      justify-content: normal;
      margin-top: ${theme.spacing(6)};
    }

    .cashback-success {
      &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        @media (min-width: ${theme.breakpoints.values.md}px) {
          margin-bottom: 80px;
        }
      }

      &__title,
      &__subtitle {
        max-width: 320px;
      }

      &__subtitle {
        margin-top: ${theme.spacing(3)};
        color: ${theme.palette.grey[400]};

        @media (min-width: ${theme.breakpoints.values.md}px) {
          max-width: 400px;
        }
      }

      &__data-content {
        display: grid;
        row-gap: ${theme.spacing(4)};

        margin-top: ${theme.spacing(6)};
        width: 100%;
        border-radius: ${theme.spacing(2)};
        background: ${theme.palette.background.default};
        padding: ${theme.spacing(3)};
        text-align: start;

        &-wrapper {
          display: grid;
          grid-template-columns: 0.4fr 0.6fr;

          word-break: break-word;
        }
      }
    }

    button {
      height: 48px;
    }
  `,
)

export const IconContainer = styled('div')(
  () => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;

    [class^='icon-'] {
      font-size: 6.875rem;
    }
  `,
)
