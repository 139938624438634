import React from 'react'

import { Text } from '@azos/shared'

import { Root } from './Emoticon.styles'

type EmoticonProps = {
  title?: string
}

const Emoticon: React.FCC<EmoticonProps> = ({ children, title = '' }) => {
  return (
    <Root>
      <Text title={title} variant="h4" component="span">
        {children}
      </Text>
    </Root>
  )
}

export default Emoticon
