import { HttpClient, UnexpectedError } from '@azos/core'
import { CreateDPSUseCase, GetDPSQuestionnaireUseCase } from 'domain/usecases'
import HttpStatusCode from 'http-status-codes'

export type IDPSRepository = GetDPSQuestionnaireUseCase & CreateDPSUseCase

export class DPSRepository implements IDPSRepository {
  constructor(private readonly httpClient: HttpClient) {}

  async getDPSQuestionnaire(
    params: GetDPSQuestionnaireUseCase.Params,
  ): Promise<GetDPSQuestionnaireUseCase.Response> {
    const { proposalId } = params
    const httpResponse = await this.httpClient.request({
      url: `/dps/${proposalId}`,
      method: 'get',
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as GetDPSQuestionnaireUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async createDPS(
    params: CreateDPSUseCase.Params,
  ): Promise<CreateDPSUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/dps`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CreateDPSUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async createDPSQuestionnaire(
    params: any,
  ): Promise<CreateDPSUseCase.Response> {
    const httpResponse = await this.httpClient.request({
      url: `/dps/proposal`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CreateDPSUseCase.Response
      default:
        throw new UnexpectedError()
    }
  }

  async generateDPS3Score(params: any): Promise<any> {
    const httpResponse = await this.httpClient.request({
      url: `/dps/score`,
      method: 'post',
      body: params,
    })

    const response = httpResponse.data

    let error: any = null

    switch (httpResponse.statusCode) {
      case HttpStatusCode.OK:
        return response as CreateDPSUseCase.Response
      case HttpStatusCode.BAD_REQUEST:
        error = new Error(response)
        error.code = HttpStatusCode.BAD_REQUEST
        error.message = response.message
        console.log('DATA', { error })
        throw error
      default:
        throw new UnexpectedError()
    }
  }
}
