import {
  css,
  styled,
  Modal as SharedModal,
  ModalProps as SharedModalProps,
} from '@azos/shared'

export const Modal = styled(SharedModal)<SharedModalProps>(
  ({ theme }) => css`
    .modal-content {
      @media (max-width: ${theme.breakpoints.values.md}px) {
        .modal-header {
          margin: 0;
        }

        .modal-content-wrapper {
          margin: ${theme.spacing(4)};
        }
      }
    }
  `,
)
