import { IProposalProducer, Services } from '@azos/core'
import { ISessionConsumer, IUTMConsumer } from '@data/adapters'
import { IQuotationsRepository } from '@data/repositories'
import { CreateQuotationUseCase } from '@domain/usecases'

type Params = CreateQuotationUseCase.Params
type Response = CreateQuotationUseCase.Response

export type ICreateQuotationService = Services<Params, Response>

export class CreateQuotationService implements ICreateQuotationService {
  constructor(
    private readonly quotationsRepository: IQuotationsRepository,
    private readonly utmConsumer: IUTMConsumer,
    private readonly sessionConsumer: ISessionConsumer,
    private readonly proposalProducer: IProposalProducer,
  ) {}

  async execute(params: Params): Promise<Response> {
    const utm = this.utmConsumer.execute()

    const session = this.sessionConsumer.execute()

    const payload = Object.assign(params, { utm }, { session })

    const quotation = await this.quotationsRepository.create(payload)

    const { proposalId, quotationId } = quotation

    this.proposalProducer.execute({ proposalId, quotationId })

    return quotation
  }
}
