import React from 'react'

import analytics from '@azos/analytics'
import { ButtonSelect, Modal, Popper, Text, Utils } from '@azos/shared'
import { Utils as B2CUtils } from '@data/utils'
import { useTheme, useMediaQuery } from '@mui/material'

import { CallPageProps } from './CallPage.props'
import { ModalContainer, Root } from './CallPage.styles'
import { PopperContent } from './components'

const ACTIONS = {
  CALLPAGE: B2CUtils.actions.general.CALLPAGE,
  CALLPAGE_MODAL: B2CUtils.actions.general.CALLPAGE_MODAL,
  CALLPAGE_POPPER: B2CUtils.actions.general.CALLPAGE_POPPER,
}

const CallPage: React.VFC<CallPageProps> = ({ onCallPageClick }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const [active, setActive] = React.useState(false)
  const [popoverAnchorEl, setPopoverAnchorEl] = React.useState(null)

  const popoverId = React.useMemo(
    () => (active ? 'transition-popper' : undefined),
    [active],
  )

  const handleClose = () => {
    setActive(false)
  }

  const handleOpen = (event: React.BaseSyntheticEvent) => {
    setPopoverAnchorEl(event.currentTarget)
    analytics.custom.action.execute({
      dataAction: Utils.dataNames.renderDataName('button', ACTIONS.CALLPAGE),
    })
    setActive(true)
  }

  const handleClickCallPage = () => {
    onCallPageClick()
    handleClose()
  }

  return (
    <>
      <Root
        title="Contrate pelo telefone"
        onClick={handleOpen}
        aria-describedby={popoverId}
        data-action={Utils.dataNames.renderDataName('button', ACTIONS.CALLPAGE)}
        type="button"
      >
        <i className="icon-dial-phone content-icon" />
        <Text component="span" className="content-text">
          Contrate pelo telefone
        </Text>
      </Root>

      {isMobile ? (
        <Modal
          open={active}
          onClose={handleClose}
          variant="bottomDrawer"
          title="Precisa de ajuda?"
          parentName={ACTIONS.CALLPAGE_MODAL}
        >
          <ModalContainer>
            <ButtonSelect
              startIcon={<i className="icon-phone" />}
              className="modal-content-button"
              title="Contrate pelo telefone"
              description="Ligamos em 45 segundos"
              onClick={handleClickCallPage}
            />
          </ModalContainer>
        </Modal>
      ) : (
        <Popper
          id={popoverId}
          open={active}
          anchorEl={popoverAnchorEl}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <PopperContent
              {...TransitionProps}
              onClose={handleClose}
              onCallPageClick={handleClickCallPage}
              parentName={ACTIONS.CALLPAGE_POPPER}
            />
          )}
        </Popper>
      )}
    </>
  )
}

export default CallPage
