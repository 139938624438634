import React from 'react'

import { makeStorageProvider } from '@azos/core'

type UTMData = {
  [key: string]: string | string[] | null
}

type GetUTMProps = {
  qs: UTMData
}

const storageProvider = makeStorageProvider({}, { crossDomain: true })

export const useWidget = ({ qs }: GetUTMProps): void => {
  React.useEffect(() => {
    const widgetOrigin = Object.entries(qs).find(
      ([key]) => key === 'widgetOrigin',
    )

    storageProvider.set('widget', !!widgetOrigin)

    // eslint-disable-next-line
  }, [])
}
