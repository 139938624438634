import React from 'react'

import { BadgeProps } from './Badge.props'
import { Root } from './Badge.styles'

const Badge: React.FC<BadgeProps> = ({
  children,
  variant = 'success',
  icon,
  ...rest
}) => (
  <Root $variant={variant} {...rest}>
    {icon && <i className={icon} />}
    {children}
  </Root>
)

Badge.displayName = 'Badge'

export default Badge
