import React from 'react'

import { Utils } from '@azos/shared'
import Typography from '@mui/material/Typography'

import { StepIcon, StepperConnector } from './components'
import { StepperProps } from './Stepper.props'
import { Step, StepContent, StepLabel, Stepper } from './Stepper.styles'

const MyStepper: React.FCC<StepperProps> = ({ steps, step, ...props }) => {
  const [activeStep, setActiveStep] = React.useState<number>(step)

  React.useEffect(() => {
    setActiveStep(step)
  }, [step])

  return (
    <Stepper
      activeStep={activeStep}
      connector={<StepperConnector />}
      orientation="vertical"
      {...props}
    >
      {steps.map((step, index) => (
        <Step key={step.label} active={index < activeStep}>
          <StepLabel
            StepIconComponent={props => (
              <StepIcon {...props}>{step.content}</StepIcon>
            )}
            parentName={step.action}
          >
            {step.label}
          </StepLabel>
          <StepContent>
            <Typography>{step.description}</Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  )
}

export default MyStepper
