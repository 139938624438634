import React from 'react'

import { useRemoteConfig } from '@azos/core'

export interface CashbackContextValue {
  isLoading: boolean
  isAvailable: boolean
  isCashbackActive: boolean
  active: (value?: boolean) => void
}

export const ConfigContext = React.createContext<CashbackContextValue>(
  {} as CashbackContextValue,
)

export const CashbackProvider: React.FCC = ({ children }) => {
  const remoteConfig = useRemoteConfig()
  const isCashbackActive = remoteConfig.get<boolean>('ENABLE_CASHBACK')
  const [active, setActiveCashback] = React.useState<boolean>(false)

  const isAvailable = React.useMemo(
    () => active && isCashbackActive,
    [active, isCashbackActive],
  )

  return (
    <ConfigContext.Provider
      value={{
        isLoading: !remoteConfig,
        isAvailable,
        isCashbackActive,
        active: (value = true) => setActiveCashback(value),
      }}
    >
      {children}
    </ConfigContext.Provider>
  )
}

export const useCashback = () => {
  const context = React.useContext(ConfigContext)

  if (!context) {
    throw new Error('The useCashback should within CashbackProvider')
  }

  return context
}
